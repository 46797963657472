import React from "react"
import styled from "styled-components"
import SEO from "../../components/seo"
import Layout from "../../components/NewLayout"

const mapStyles = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%"
};

const StormPage = () => (
  
<Layout>
  <SEO title="Home" />
  <h1 
    style={{marginTop: "15rem", 
    marginLeft: "5rem", 
    marginBottom: "10rem"
    }}>Welcome to the Storm Salon page <br/> Under Construction
  </h1>
  <MapWrapper>
    <iframe title="Tour of the Salon" style={mapStyles} src="https://www.google.com/maps/embed?pb=!4v1589715179972!6m8!1m7!1sCAoSLEFGMVFpcE1rZlFIRzBaNnBKdGRSLTZVOFlUbUhqOWFza1NHaUJxV3hNUzQt!2m2!1d51.12398532931227!2d-2.74395864543078!3f242.451106041085!4f-3.326964721596937!5f0.7820865974627469"></iframe>
  </MapWrapper>
</Layout>
    

)

export default StormPage

const MapWrapper = styled.div`
  display: none;
  grid-column: 2;
  grid-row: 1;
  position: relative;
  padding-bottom: 75% /* 4:3 */;
  width: 100%;
`